body {
  margin: 0;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

:root {
  --background-white: #ffffff;
  --background-primary: #253551;
  --background-secondary: #b9c7df;
  --text-primary: #333;
  --border: #333;
}
